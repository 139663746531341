.boton_estandar {
	background-color: red !important;
	border-radius: 25px;
	background: rgb(199, 0, 0);
	background: linear-gradient(36deg, #d039de 0%, #93339c 100%);
	color: #fff !important;
	border-radius: 30px !important;
}

.boton_estandar:hover {
	opacity: 0.8;
	color: #fff;
}

body {
	/* juanita */
	background: url('../img/fondo_sistema.jpg') no-repeat center center fixed;
	background-size: cover;
	background-position: center center;
	overflow-y: hidden;
	overflow: hidden !important;
}

html {
	overflow-y: hidden;
}

.Login {
	margin: 5% 0px;
}

.contLogin {
	width: 500px !important;
	border-radius: 45px;
	padding: 10px 10px;
	font-weight: 400;
	background-color: #fff;
	border: 0;
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.btn_atras {
	border: none;
	background-color: transparent;
	color: #ff0c00;
}

.Logo_login {
	width: 200px !important;
	margin-top: 30px;
}

.logoMenu {
	width: 150px !important;
}

.menu {
	background-color: #fff;
	z-index: 1;
	height: 5% !important;
}

.BarraIzquierdaPos {
	height: 91.2%;
	width: 40%;
	position: absolute;
	left: 0px;
	bottom: 0px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	padding: 0px 0px 100px 0px;
	z-index: 1;
}

.BarraDerechaPos {
	height: 91.2%;
	width: 60%;
	position: absolute;
	right: 0px;
	bottom: 0px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	padding: 0px 0px 0px 0px;
	z-index: 1;
}

.overflowY {
	overflow-y: scroll;
}


.sombra {
	box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.16);
}

.overflow {
	overflow: hidden !important;
}

/*Card Pedidos*/

.CardPedido {
	width: 100%;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	padding: 10px 5px;
	margin-bottom: -5px;
	background-color: #fff;
}

.infoCardPedido h5 {
	margin-bottom: 0px;
	font-size: 18px;
	margin-left: 15px;
}

.infoCardPedido p {
	color: #aaa;
	margin-bottom: 0px;
	margin-left: 15px;
	font-weight: 300 !important;
}

.infoCardPedido span {
	margin-top: 0px;
	margin-left: 15px;
	font-weight: 300 !important;
}

.btnCardPedido {
	height: 62px;
	width: 62px;
	border-radius: 45px;
	background-color: #fff0ef;
	float: right;
	padding: 16px 0px;
	text-align: center;
	margin-right: 3px;
	color: #222;
	border: none;
}

.btnCardPedido span {
	font-size: 20px;
}

.gris {
	background-color: #f6f6f6 !important;
}

.naranja {
	color: #ff0c00;
}

.btnCardPedido:hover {
	color: #ff0c00;
	opacity: 0.8;
}

.contCardsPedido {
	margin: 0px;
	padding: 0px 0px 30px 0px;
	height: 76%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.contCardsPedido::-webkit-scrollbar {
	width: 8px;
	/* Tamaño del scroll en vertical */
	height: 8px;
	/* Tamaño del scroll en horizontal */
}

.contCardsPedido::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.contCardsPedido::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.contCardsPedido::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

/*Subtotales*/

.contSubtotales {
	height: 35%;
	width: 100%;
	padding: 15px 20px 30px 30px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	position: absolute;
	bottom: 0px;
}

.cont_text_Subtotales p {
	margin-bottom: 0px;
}

.cont_val_Subtotales p,
.cont_val_Subtotales h4 {
	margin-bottom: 0px;
	text-align: right;
}

/*Bones Pos*/

.cont_botones_pos {
	padding: 0px 0px;
	width: 100;
	margin-top: 15px;
}

.Btn_Pos_opciones {
	background: linear-gradient(36deg, rgba(199, 0, 0, 1) 0%, rgba(255, 130, 54, 1) 100%);
	color: #fff !important;
	height: 62px;
	width: 62px;
	border-radius: 45px;
	padding: 12px 0px;
	text-align: center;
	float: left;
	font-size: 25px;
}

.gigante {
	padding: 25px 10px !important;
}

.rendon {
	border-radius: 50px;
}

.mitad {
	float: left;
	padding-right: 5px;
}

.cont1 a {
	font-size: 30px !important;
	padding: 15px 0px !important;
}

/*Categorias Pos*/

.CatPos {
	padding: 5px;
}

.contBotones {
	padding: 0px 10px 0px 0px;
}

.btnCate {
	width: 100%;
	height: 60px;
	background-color: #ffd3bc;
	border-radius: 30px;
	text-align: center;
	padding: 15px 10px !important;
}

.contCategorias {
	padding: 10px 15px 10px 10px;
	margin: 0px;
	width: 100%;
	overflow: hidden;
	height: 20%;
	overflow-y: scroll;
}

.contProductos {
	padding: 10px 0px 10px 10px;
	width: 100%;
	overflow-y: scroll;
	margin: 0px;
	height: 80%;
}

.contProductos::-webkit-scrollbar,
.contCategorias::-webkit-scrollbar {
	width: 8px;
	/* Tamaño del scroll en vertical */
	height: 8px;
	/* Tamaño del scroll en horizontal */
}

.contProductos::-webkit-scrollbar-thumb,
.contCategorias::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.contProductos::-webkit-scrollbar-thumb:hover,
.contCategorias::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.contProductos::-webkit-scrollbar-thumb:active,
.contCategorias::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.btnProducto {
	width: 100%;
	height: 150px;
	background-color: #eee;
	border-radius: 30px;
	text-align: center;
	padding: 0px !important;
	overflow: hidden;
}

.CatProductos {
	padding: 5px;
}

.Agotado {
	opacity: 0.6;
}

.Agotado h5,
.Agotado p {
	color: gray !important;
}

.CatProductos h5 {
	text-align: center;
	font-size: 17px;
	margin: 5px 0px -3px 0px;
	font-weight: bold;
}

.CatProductos p {
	text-align: center;
	margin: 0px;
}

.tachado {
	text-decoration: line-through;
	color: #999;
}

/*clases modal*/
.cont_modal {
	padding: 5px 20px;
}

.modal-content {
	border-bottom-right-radius: 30px !important;
	border-top-right-radius: 30px !important;
}

.modal-body {
	padding: 0px 0px 35px 0px;
}

.cont_derecho_modal {
	text-align: right;
}

.modal-header {
	border: none;
}

.cont_derecho_modal svg {
	font-size: 30px;
}

.tituloModal {
	margin: 20px 30px !important;
}

.btn_cat_atributo {
	text-align: left !important;
	border: none !important;
	padding: 0 !important;
	font-size: 15px;
}

.sinPadding {
	padding: 0px;
	z-index: 1 !important;
}

.tituloAtributo {
	padding: 5px 10px;
}

.cont_Atributos {
	width: 100%;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin: 0px !important;
	padding: 5px 0px;
	margin-bottom: 0px;
	background-color: #fff;
}

.cont_Atributos p {
	padding-top: 15px;
	font-weight: normal;
	color: #616161;
}

.checkBoton {
	padding-bottom: 25px;
}

#checkbox1 input:checked {
	background-color: red;
}

.check {
	color: #fff !important;
}

.btnDetalle {
	text-align: left;
	border: none;
	background-color: transparent;
	padding: 0px 0px 0px 10px;
	font-weight: normal;
}

.btnDetalle:hover {
	color: #ff0c00;
}

.fotoProdModal {
	background-color: #f9f9f9;
	padding: 20px;
	text-align: center;
}

.PreciosProdModal {
	text-align: center;
	padding: 5px;
}

.cardAtriProd {
	padding: 10px;
	text-align: center;
}

.headerPasarela {
	font-weight: bold;
	padding: 20px 20px 5px 20px;
	text-align: center;
}

.CajaPasarela {
	background-color: #f9f9f9;
	margin: 10px 30px;
	padding: 20px;
	border-radius: 20px;
}

.cont_botones_pasarela {
	margin: 10px 30px;
	/*margin-bottom: 200px;*/
	height: 100%;
	padding-bottom: 250px;
}

.cont_billetes {
	padding: 0px 5px;
	text-align: center;
}

.btn_billete {
	background-color: #fff;
	border-radius: 8px;
	padding: 15px 10px;
	float: left;
	margin-left: 5px;
	border: 1px solid #5c9252;
	color: #5e7b5b;
}

.tituloMedios {
	text-align: center;
	padding: 0px;
	margin-top: 15px;
	margin-bottom: 5px;
}

.itemMedio {
	padding: 5px 10px;
	text-align: left;
	padding-bottom: 15px;
}

.nombreMedio {
	padding-top: 10px;
	font-size: 16px;
}

.ModalPago,
.modal-content {
	border-radius: 25px !important;
}

.hidden {
	display: none;
}

.cont_tiket {
	height: 0px;
	width: 327px;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	margin: 0px 0px;
}


.cont_tiket h4 {
	font-size: 30px;
	font-weight: bold;
}

/*Mientras se hace responsive*/

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1230px) {

	.infoCardPedido h5 {
		font-size: 14px;
	}

	.infoCardPedido p {
		font-size: 13px;
	}

	.infoCardPedido span {
		font-size: 13px;
	}

	.btnCardPedido {
		height: 52px;
		width: 52px;
		padding: 12px 0px !important;
	}


}

@media screen and (max-width: 1024px) {

	.infoCardPedido h5 {
		font-size: 14px;
	}

	.infoCardPedido p {
		font-size: 13px;
	}

	.infoCardPedido span {
		font-size: 13px;
	}

	.btnCardPedido {
		height: 52px;
		width: 52px;
		padding: 15px -10px;
		text-align: center;
	}

	.btnCardPedido span {
		padding: 0px;
	}


}

.ModalPago .active {
	background-color: #fff !important;
}

.estado_caja {
	border: 2px solid #50bd50;
	padding: 5px 10px;
	border-radius: 18px;
	font-size: 13px;
	background-color: #50bd50;
	color: #fff;
}

.notificacion {
	border: 2px solid #df6b0c;
	padding: 5px 10px;
	border-radius: 18px;
	font-size: 13px;
	background-color: #e66f0e;
	color: #fff;
	margin-right: 10px;
}

.estado_caja_cerrada {
	border: 2px solid #ec6c49;
	padding: 5px 10px;
	border-radius: 18px;
	font-size: 13px;
	background-color: #ec6c49;
	color: #fff;
}

.usuarioMenu span {
	margin-left: 10px;
}

.usuarioMenu div {
	height: 20px;
	width: 20px;
	border-radius: 100px;
	padding: 0px 8px;
	float: right;
	overflow: hidden;
	margin-left: 10px;
	margin-top: 2px;
}

.usuarioMenu img {

	height: 100%;
	margin-left: -8px;
	margin-top: -7px;
}

.cerrarMenu {
	border: none !important;
	padding: 7px 10px;
	border-radius: 18px;
	font-size: 13px;
	background: linear-gradient(36deg, rgba(199, 0, 0, 1) 0%, rgba(255, 130, 54, 1) 100%);
	color: #fff;
	margin-left: 10px;
}

.lineaFactura {
	border-top: 2px dashed #000;
	/*margin-top: -5px;*/
}

.tabla_tiquete td {
	border-top: none !important;
	padding: 2px !important;
	color: #000;
	font-weight: bold;
	text-align: left;
}

.combina_tiquete {
	text-align: left;
	padding: 0px 10px 0px 20px;
	width: 320px;
	height: auto;
	word-wrap: break-word;
}

.table-responsive {
	margin-bottom: -20px !important;
	padding: 0px 10px;
}

.table-responsive tr td:last-child {
	text-align: right !important;
}


.imageProducto {
	width: 100%;
	height: auto;
	border-radius: 5px;
}

@supports(object-fit: cover) {
	.imageProducto {
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}

.descPasaerla input {
	margin: 0px 0px !important;
	background-color: #fff !important;
	height: 40px !important;
}

.descPasaerla div {
	margin: 10px 0px !important;
}

.domicilios p {
	margin-top: 20px !important;
	font-size: 18px;
}

.labelPasare {
	margin-top: 20px !important;
	font-size: 18px;
}

.domicilios {
	margin-top: 5px;
}

.altoModal {
	height: 310px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.textDomi {
	font-size: 19px;
}

.desabili {
	color: rgb(145, 145, 145) !important;
	font-weight: 400;
}

/* ------------------ loader -------------------------- */
.loader,
.loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}

.loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid #0d376e;
	border-right: 1.1em solid #0d376e;
	border-bottom: 1.1em solid #0d376e;
	border-left: 1.1em solid #1874f0;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
	.Logo_login {
		width: auto !important;
	}

	.contLogin {
		width: auto !important;
	}
}